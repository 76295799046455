import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00cef167"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type"]
const _hoisted_2 = {
  key: 0,
  class: "icon icon_plus"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", { btn_outline: $props.outline, btn_disabled: $props.disabled, btn_small: $props.small, btn_icon: $props.outline, btn_white: $props.white }]),
    type: $props.type,
    onClick: $setup.clickHandler
  }, [
    ($props.outline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}