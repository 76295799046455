import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27a4dc8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty-layout"
}
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return ($setup.isFullPageLayout)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_view)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["main-layout", { 'main-layout_collapsed': $setup.collapsed }])
      }, [
        _createVNode($setup["AppSidebar"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["AppHeader"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component }) => [
                _createVNode(_Transition, {
                  name: "fade",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            })
          ])
        ])
      ], 2))
}