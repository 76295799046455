import { defineStore } from "pinia";
import { IVideographer } from "@/types/entities/IVideographer";
import { computed, ComputedRef, ref, Ref } from "vue";

export const useVideographerStore = defineStore(
  "videographer",
  () => {
    const activeVideographers: Ref<IVideographer[]> = ref([]);
    const croppedVideographers: ComputedRef<IVideographer[]> = computed(() =>
      activeVideographers.value.slice(0, 5)
    );
    const hiddenVideographers: ComputedRef<IVideographer[]> = computed(() =>
      activeVideographers.value.slice(5)
    );
    const setActiveVideographers = (data: IVideographer[]): void => {
      activeVideographers.value = data;
    };
    const getVideographerById = (id: number): IVideographer | null => {
      return activeVideographers.value.find((v) => v.id === id) || null;
    };

    return {
      activeVideographers,
      setActiveVideographers,
      croppedVideographers,
      hiddenVideographers,
      getVideographerById,
    };
  },
  {
    persist: {
      paths: [],
    },
  }
);
