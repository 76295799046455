import { defineStore } from "pinia";
import { computed, ComputedRef, Ref, ref } from "vue";
import { CalendarViewEnum } from "@/utils/enum/CalendarViewEnum";

export const useUIStore = defineStore(
  "ui",
  () => {
    const viewType: Ref<CalendarViewEnum> = ref(CalendarViewEnum.table);
    const viewTypeTable: ComputedRef<boolean> = computed(
      () => viewType.value === CalendarViewEnum.table
    );
    const viewTypeCalendar: ComputedRef<boolean> = computed(
      () => viewType.value === CalendarViewEnum.calendar
    );
    const toggleViewType = (type: CalendarViewEnum) => {
      viewType.value = type;
    };

    const collapsed: Ref<boolean> = ref(false);
    const collapseSidebar = (): void => {
      collapsed.value = !collapsed.value;
    };

    return {
      toggleViewType,
      viewType,
      viewTypeTable,
      viewTypeCalendar,
      collapsed,
      collapseSidebar,
    };
  },
  {
    persist: {
      paths: ["viewType", "collapsed"],
    },
  }
);
