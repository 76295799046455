import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/scss/styles.scss";
import { createPinia } from "pinia";
import VueClickAway from "vue3-click-away";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import VCalendar from "v-calendar";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));

const app = createApp(App);

document.addEventListener("DOMContentLoaded", async () => {
  app.use(pinia).use(router).use(VueClickAway).use(VCalendar, {}).mount("#app");
});
