import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d54296c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-menu-wrapper" }
const _hoisted_2 = { class: "menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AppButton"], {
      class: _normalizeClass(["btn btn_more", { btn_disabled: $props.disabled, btn_active: $setup.showDropdown }]),
      type: "button",
      onOnClick: $setup.clickHandler
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["class"]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (v) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: "app-subtitle",
          key: v.id,
          to: { name: $setup.routesName.ordersVideographer, params: { id: v.id } }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(`${v.first_name} ${v.last_name}`), 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ], 512), [
      [_vShow, $setup.showDropdown]
    ])
  ])), [
    [_directive_click_away, $setup.clickOutside]
  ])
}