import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f6aaee1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-head" }
const _hoisted_2 = {
  key: 0,
  class: "short-logo"
}
const _hoisted_3 = {
  key: 1,
  class: "logo"
}
const _hoisted_4 = { class: "sidebar-list" }
const _hoisted_5 = { class: "app-subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-sidebar", { 'app-sidebar_collapsed': $setup.collapsed }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.collapsed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3)),
      _createElementVNode("div", {
        class: _normalizeClass(["icon icon_collapse", { icon_collapsed: $setup.collapsed }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.collapseSidebar && $setup.collapseSidebar(...args)))
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.availableLinks, (link, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: _normalizeClass(["link mb1", [{ link_hide: $setup.collapsed }, { link_active: $setup.currentRouteType === link.routeType }]]),
          key: index,
          to: { name: link.route },
          "active-class": "link_active"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["icon", link.iconName])
            }, null, 2),
            _createElementVNode("div", _hoisted_5, _toDisplayString(link.name), 1)
          ]),
          _: 2
        }, 1032, ["to", "class"]))
      }), 128))
    ])
  ], 2))
}