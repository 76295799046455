const routesMetaTypes = {
  users: "users",
  orders: "orders",
  videographers: "videographers",
  corporateClients: "corporate-clients",
  coupons: "coupons",
  newGames: "new-games",
  activeGames: "active-games",
  changedGames: "changed-games",
  onHoldGames: "on-hold-games",
  completedGames: "completed-games",
  canceledGames: "canceled-games",
  depositForSeason: "deposit-for-season",
  liveStreaming: "live-streaming",
  additionalWindows: "additional-windows",
  scoutGames: "scout-games",
  statistics: "statistics",
};

export default routesMetaTypes;
