import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router/routes";
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const { isAdmin } = storeToRefs(useUserStore());
  if (to.matched.some((record) => record.meta.admin)) {
    if (isAdmin.value) {
      next();
      return;
    }
    next({
      path: "/orders",
    });
    return;
  }
  if (to.matched.some((record) => record.meta.protected)) {
    if (userStore.accessToken) {
      next();
      return;
    }
    next({
      path: "/login",
      query: { redirect: to.path },
    });
    return;
  }
  if (to.matched.some((record) => record.meta.notAuthorized)) {
    if (!userStore.accessToken) {
      next();
      return;
    } else {
      next({
        path: "/orders",
      });
      return;
    }
  }
  next();
});

export default router;
